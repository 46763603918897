import { useCallback } from 'react';

export function useLeadForm() {
    const onSubmit = useCallback(async (params): Promise<any> => {
        try {
            if (!params) {
                return Promise.reject({ message: 'Missing params' });
            }

            const res = await fetch('/api/lead/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(params),
            });

            const json = await res.json();

            if (json?.message === 'OK') {
                return Promise.resolve(json);
            } else {
                if (res.status === 400) {
                    return Promise.reject(json);
                } else {
                    return Promise.reject(json);
                }
            }
        } catch (e) {
            console.error(e);
        }
    }, []);

    return onSubmit;
}
