import React, { useCallback, useState, useEffect } from 'react';
import Cookies from 'cookies-js';
import {
    PopupSection as PopupSectionDS,
    PopupSectionProps,
} from '@solublestudio/legalbrave-design-system';
import { useTranslate } from '../../hooks/useTranslate';
import { useLeadForm } from '../../hooks/useLeadForm';
import { Config } from 'api-base/lib/types';

const PopupSection = ({
    globalConfig,
    frequencyOfAppearance = 1,
    ...props
}: PopupSectionProps & {
    globalConfig: Config;
    frequencyOfAppearance: number;
}) => {
    const [t] = useTranslate();
    const [showCode, setShowCode] = useState(false);
    const [show, setShow] = useState(false);

    const setCookie = useCallback(() => {
        Cookies.set(globalConfig.web.cookies.popup ?? '', 'true', {
            path: '/',
            secure: true,
            expires: 60 * 60 * 24 * frequencyOfAppearance, // 1 day
        });
    }, []);

    function initPopup() {
        const currentCookie = Cookies.get(globalConfig.web.cookies.popup ?? '');

        if (!currentCookie) {
            setShow(true);
        }
    }

    useEffect(() => {
        if (typeof window === 'undefined') return;
        window.addEventListener('CookiebotOnLoad', initPopup);
        return () => {
            window.removeEventListener('CookiebotOnLoad', initPopup);
        }
    }, []);

    const handleSubmit = useLeadForm();
    const onSubmit = useCallback(async (email: string): Promise<string> => {
        try {
            await handleSubmit({ email, source: 'Pop-up' });
            setShowCode(true);
            return t('header.callme.success');
        } catch (e) {
            console.error(e);
            return t('header.callme.error');
        }
    }, []);

    return (
        show && (
            <PopupSectionDS
                {...props}
                onSubmit={onSubmit}
                onClose={setCookie}
                showCode={showCode}
            />
        )
    );
};

export default PopupSection;
